import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    "& h4": {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    "& hr": {
      borderColor: theme.palette.secondary.main,
      width: '100%',
      marginBottom: 30,
    }
  },
}));

const BuyersGuide = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <br />
      <br />
      <Typography variant="h4" color="primary" gutterBottom>Buyer’s Guide</Typography>
      <hr />
      <br />
    </div>
  );
}

export default BuyersGuide;