import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import uiConfig from '../../uiConfig';
import { inject } from "mobx-react";
import usePortal from "../hooks/portal";
import Sponsors from "../Sponsors";
import { LogOnMount } from 'react-amplitude-hooks';
import EventsGrid from "../EventsGrid";
import ProductsGrid from "../ProductsGrid";
import * as Scroll from 'react-scroll';
// import { Redirect } from "react-router-dom";
import api from '../../api'
import Schedule from "../Schedule";

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight, backgroundRatio, justifyContent, alignItems }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      minHeight: backgroundRatio ? undefined : minHeight,
      height: backgroundRatio ? window.innerWidth * backgroundRatio : height,
      backgroundSize: backgroundRatio ? 'contain !important' : 'cover !important',
      display: 'flex',
      justifyContent,
      alignItems,
      [theme.breakpoints.down('sm')]: {
        minHeight: 250,
        // backgroundSize: 'cover !important',
        backgroundPositionY: '0% !important'
      },
    }),
    paper: ({ paperMargin, paperMarginType, paperMaxWidth, paperColor }) => ({
      ["margin" + paperMarginType]: paperMargin,
      maxWidth: paperMaxWidth,
      background: paperColor,
      padding: theme.spacing(6, 8),
      [theme.breakpoints.down('sm')]: {
        // maxWidth: '80vw',
        // padding: '30px 20px',
        margin: '0 auto',
      },
    }),
    ctaContainer: {
      textAlign: 'center',
      width: '100%',
      margin: theme.spacing(0),
      // marginRight: '35vw',
      // marginBottom: '2vw',
    },
    title: ({ fontFamily, fontWeight, color }) => ({
      // color: theme.palette.primary.main,
      color,
      textAlign: 'center',
      fontFamily,
      fontWeight,
      whiteSpace: 'pre-line'
    }),
    list: ({ fontFamily, fontWeight, color }) => ({
      color, //theme.palette.primary.main,
      fontFamily,
      fontWeight,
      fontSize: '1.2rem',
    }),
    ctaButton: ({ fontWeight, ctaPadding }) => ({
      margin: 10,
      padding: theme.spacing(...ctaPadding),
      // fontWeight: 600,
      fontSize: 25,
      // background: '#f6A243',
      // letterSpacing: 3,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        padding: theme.spacing(1.5, 3),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    }),
    sponsors: {
      // width: '100vw',
      // position: 'relative',
      // left: '50%',
      // right: '50%',
      // marginLeft: '-50vw',
      // marginRight: '-50vw',
      // paddingLeft: 20,
      textAlign: 'center',
      "& h4": {
        // fontWeight: 600,
        textTransform: 'uppercase',
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        width: '100%',
        marginBottom: 30,
      }
    },
    heading: {
      "& h4": {
        // fontWeight: 600,
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        // width: '94%',
        marginBottom: 10,
      }
    },
  })
});

const HomePage = ({ isLoggedIn, store, events, products, portal }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [backgroundImage, setBackgroundImage] = useState("");
  const [schedule, setSchedule] = useState([]);
  const { urlPrefix, data: { home: data }, uiSettings: { home: uiSettings }, uiSettings: { signInRedirect } } = usePortal();
  const classes = useStyles(uiSettings);
  let background = `url(${backgroundImage}) ${uiSettings.backgroundPosition.x}% ${uiSettings.backgroundPosition.y}% no-repeat`;

  useEffect(() => {
    if (!uiConfig.showSchedule)
      return;
    let didCancel = false;
    const event = events.length > 0 ? events[events.length - 1] : null;
    if (!event?.id) return;
    api.getSchedule(event.id).then(data => {
      if (didCancel) return;
      setSchedule(data);
    });
    return () => didCancel = true;
  }, [events]);

  const scrollToEvents = () => {
    Scroll.scroller.scrollTo('events-start', {
      smooth: true,
      offset: -90,
    })
  }

  useEffect(() => {
    if (backgroundImage)
      return;
    store.setIsFetching(true);
    const loadBackground = async () => {
      const { default: url } = await import('../../assets/images/' + uiSettings.backgroundUrl);
      //console.log(url);
      setBackgroundImage(url);
      store.setIsFetching(false);
    }
    loadBackground();
  }, [uiSettings.backgroundUrl, store, backgroundImage]);
  if (!backgroundImage) return null;

  // if (isLoggedIn) return <Redirect to={signInRedirect} />

  return (
    <>
      <LogOnMount eventType="home visit" eventProperties={{ page: "home" }} />
      {uiSettings.showHero &&
        <div className={classes.fullWidth} style={{ background }}>
          {uiSettings.showCta &&
            <Paper className={classes.paper} elevation={0}>
              {uiSettings.showCtaText &&
                <>
                  <Typography variant={isSm ? "h5" : "h4"} className={classes.title} gutterBottom>{data.mainText}</Typography>
                  {/* <Typography variant="subtitle2" className={classes.title}>{data.secondaryText}</Typography> */}
                  <Typography component="div" className={classes.list}>
                    <ul>
                      {data.list.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                  </Typography>
                </>
              }
              <div className={classes.ctaContainer}>
                {/* {events.some(x => x.hasAccess) ?
              <Button className={classes.ctaButton} to={urlPrefix + ((isLoggedIn || !uiConfig.requireAuth) ? signInRedirect : uiSettings.getLink)} component={Link} variant="contained" color={uiSettings.buttonColor} size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button>
              :
              events.length > 0 && <Button className={classes.ctaButton} onClick={scrollToEvents} variant="contained" color="secondary" size="large">Register</Button>
            } */}
                {/* <Button className={classes.ctaButton} to={urlPrefix + ((isLoggedIn || !uiConfig.requireAuth) ? signInRedirect : uiSettings.getLink)} component={Link} variant="contained" color={uiSettings.buttonColor} size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button> */}
                <Button className={classes.ctaButton} onClick={scrollToEvents} variant="contained" color="secondary" size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button>
              </div>
            </Paper>
          }
        </div>
      }
      <br />
      <Container>
        <Typography variant="h6">
          The genitourinary medical oncology series developed under the leadership of Neal Shore, MD, is designed to advance cancer care education within the entire LUGPA community.  This series will feature leading experts in the field that will discuss the latest research and information for patient care. 
          <MuiLink color="secondary" href="https://www.lugpa.org/index.php?option=com_content&view=article&id=229:innovation-theater&catid=20:site-content" rel="noopener noreferrer" target="_blank"> Find out more about LUGPA Innovation Theater’s GU Series and more educational programs.</MuiLink>
        </Typography>
      </Container>
      {(uiConfig.showProducts && products.length > 0 && !events.some(x => x.hasAccess)) &&
        // <Container>
        <div className={classes.heading}>
          <Scroll.Element name="products-start" />
          <br />
          <Typography variant="h4" color="primary" gutterBottom>Select Option</Typography>
          <hr />
          <ProductsGrid products={products} />
        </div>
        // </Container>
      }
      {(uiConfig.showEvents && events.length > 0) &&
        <div className={classes.heading}>
          <Scroll.Element name="events-start" />
          <br />
          {/* <Typography variant="h4" color="primary" gutterBottom>Genitourinary (GU) Medical Oncology Series</Typography> */}
          {/* <hr /> */}
          <br />
          <EventsGrid events={events} />
        </div>
      }
      {uiConfig.showSchedule &&
        <div className={classes.heading}>
          <br />
          <Typography variant="h4" color="primary" gutterBottom>Schedule</Typography>
          <Schedule data={schedule} />
        </div>
      }
      {(uiConfig.showSponsors && portal.sponsorship?.length > 0) &&
        <div className={classes.sponsors}>
          <br />
          <br />
          {portal.sponsorship.map(level =>
            <Fragment key={level.id}>
              <Typography variant="h4" color="primary">{level.title || "Sponsors"}</Typography>
              {/* <hr /> */}
              {/* <br /> */}
              <Sponsors data={level.sponsors} />
            </Fragment>
          )}
        </div>
      }
    </>
  );
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, events: store.events, products: store.products, portal: store.portal, store
}))(HomePage);
