/* eslint-disable */
const config = {
  apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://slidespielspeakersapi.azurewebsites.net",
  portalPrice: 40,
  portalPriceForMembers: 30,
  stripeKey: "pk_test_0hlApLH5MBAWQzgK4nfccP1H",
  url: "",
  env: "development",
  portalName: "lugpa-ce",
  title: "LUGPA Webinars Series",
  hasCme: false,
  questionsBeforeRecording: false,
  portals: [
    { year: 2021, id: "e8a8abfa-6d5b-46ed-9867-613d7cfe5f2b" },
  ],
  defaultYear: 2021,
  // amplitudeKey: "5a691f5d28c551997691879d728c1da7",
  version: "0.1.8",
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    "apiHost": "https://slidespielportalsapi.azurewebsites.net",
    "stripeKey": "pk_live_nJvbydZp1mBjTIkW4mzW33B5",
    "env": "production",
    "sentryDsn": "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
