import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { inject } from "mobx-react";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAmplitude } from "react-amplitude-hooks";

import api from "../../api";
import usePortal from "../hooks/portal";
import { useFeedback } from "../feedback/Service";
import uiConfig from "../../uiConfig";
import CloseButton from "../CloseButton";

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 90,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  support: {
    //marginBottom: 0,
    minWidth: 210,
    marginLeft: 15,
  },
}));

const SignInDialog = ({ store, portal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { logEvent } = useAmplitude({ page: "login" });
  const feedback = useFeedback();
  const history = useHistory();
  const { portalName: name, portalId, urlPrefix, uiSettings } = usePortal();
  const classes = useStyles();
  // const redirectUrl = urlPrefix || "/";
  const { pathname } = useLocation();
  const redirectUrl = pathname === "/login" ? urlPrefix + uiSettings.signInRedirect : pathname.replace("/login", "");
  const backUrl = (pathname === "/login" || pathname.includes("event/")) ? urlPrefix : pathname.replace("/login", "");

  const handleClose = () => history.push(backUrl);
  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    api.login(email, password, portalId)
      .end((error, response) => {
        if (error) {
          setLoading(false);
          console.warn("LOGIN ERROR", error, error.response);
          let text = "Unknown error";
          if (error.response?.text)
            text = JSON.parse(error.response.text).error_description;
          feedback.snackbar({ text, type: "error" })
        }
        else {
          localStorage.setItem(`${name}-access_token`, response.body.access_token);
          api.getUser("me", response.body.access_token).end((err, resp) => {
            if (err) {
              setLoading(false);
              feedback.snackbar({ text: JSON.parse(error.response.text).error_description, type: "error" })
            }
            else {
              logEvent("login")
              store.setUser(resp.body);
              store.fetchEvents().then(() => {
                setLoading(false);
                history.push(redirectUrl);
              });
            }
          });
        }
      })
  }
  const handleResetPassword = async e => {
    e.preventDefault();
    const email = await feedback.form({ title: "Reset Password", input: { title: 'Email', type: "email", name: "email" } });
    console.log(email);
    if (loading) return;
    if (!email) return;
    setLoading(true);
    try {
      const response = await api.resetPassword(email, portalId);
      console.log(response);
      feedback.snackbar({ text: "Success! You will get email with password recovery instructions.", type: "success" })
    }
    catch (ex) {
      console.warn(ex.response);
      feedback.snackbar({ text: ex.response?.body?.message || "Unknown error, please contact support", type: "error" });
    }
    setLoading(false);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open onClose={handleClose} aria-labelledby="signin-dialog-title" fullWidth fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={handleClose} />}
      <DialogTitle id="signin-dialog-title">Sign In</DialogTitle>
      <DialogContent>
        {uiConfig.loginText &&
          <DialogContentText>
            {uiConfig.loginText}<br />
          </DialogContentText>}
        <form onSubmit={handleSubmit} id="signin-form" className={classes.form}>
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          {portal?.passwordAuth &&
            <>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
              <Typography variant="body2">
                <Link href="#reset" onClick={handleResetPassword}>Forgot password?</Link>
              </Typography>
            </>
          }
        </form>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            form="signin-form"
          >
            Sign In
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogContent>
      <DialogActions>
        <Typography className={classes.support} variant="body2" gutterBottom>Support: <Link href={`mailto:${uiConfig.supportEmail}`}>{uiConfig.supportEmail}</Link></Typography>
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

SignInDialog.propTypes = {
  store: PropTypes.object.isRequired
};


export default inject(({ store }, props) => ({
  portal: store.portal, store
}))(SignInDialog);
