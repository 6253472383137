const config = {
  CMEfilter: false,
  mainColor: "#2e4c54",
  primaryContrastText: "#fff",
  secondaryColor: "#4097be",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showOnDemand: false,
  showBuy: false,
  showSignUp: false,
  showSponsors: false,
  showEventSponsors: true,
  showSponsorsPage: false,
  sponsorsPageCards: false,
  sponsorsCardsHorizontal: false,
  showEvents: true,
  eventsDebugMode: false,
  showProducts: false,
  showSchedule: false,
  trackPageScrollOffset: -80,
  showExhibitHall: true,
  showChat: true,
  enablePlaybackTracking: false,
  showPrivacyPolicy: false,
  // externalPrivacyPolicyUrl: "//www.ya.ru",
  // acceptPrivacyPolicyText: "Accept <Link>",
  emailHelperText: "",
  showSessionEvaluation: false,
  showRecordingEvaluation: false,
  showAskSpeaker: false,
  supportEmail: "info@slidespiel.com",
  portals: [
    { 
      year: 2021,
      logo: 'logo.jpg',
      signInRedirect: "/",
      trackImages: true,
      home: {
        minHeight: 500,
        height: '70vh',
        paperMarginType: 'Right',
        paperMargin: '15vw',
        paperMaxWidth: 800,
        paperColor: 'rgba(0,0,0,.0)',
        backgroundUrl: 'background.jpg',
        backgroundPosition: { x: 50, y: 40 },
        backgroundRatio: 0.2637,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        color: "#fff",
        buttonColor: "secondary",
        getLink: "/",
        showHero: true,
        showCta: false,
        showCtaText: false,
        ctaPadding: [2, 4],
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 5  ,
    logoPadding: 0,
    logoHeight: 70,
    logoHeightXs: 50,
    progressBarTop: 79,
    progressBarTopXs: 55,
    childrenMarginTop: 79,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "primary"
  },
  footer: {
    // facebookUrl: "https://www.facebook.com/",
    // twitterUrl: "https://twitter.com/",
    // instagramUrl: "https://instagram.com/",
    // linkedInUrl: "https://www.linkedin.com/",
  },
  eventHome: {
    titleColor: "primary",
    subtitleColor: "secondary",
    liveColor: "secondary",
    registerColor: "primary",
    exhibitHallColor: "secondary",
    networkingRoomColor: "primary",
  }
};

export default config;
