import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from 'react-router-dom';
import usePortal from './hooks/portal';
import moment from 'moment';
import { formatDateInterval } from '../utils';
import uiConfig from '../uiConfig';

const useStyles = makeStyles((theme) => ({
  image: {
  },
  bar: {
    height: 40,
    zIndex: 10,
    background: "rgba(0, 0, 0, 0.7)",
  },
  tile: {
    "& img": {
      // transform: 'scale(1) translateX(-50%)',
      transition: 'transform .5s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.06) translateX(-47%)',
      }
    }
  },
  bottomBar: {
    height: 80,
    background: "rgba(0, 0, 0, 0.8)",
    // zIndex: 10,
  },
  title: {
    whiteSpace: "normal",
    lineHeight: 1.3,
    marginBottom: 5,
  },
}));

const EventsGrid = ({ events }) => {
  const { urlPrefix } = usePortal();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <GridList cellHeight={230} cols={isSm ? 1 : 4}>
        {events.filter(x => moment.duration(moment() - moment(x.endDate || x.startDate)).asDays() < (uiConfig.eventsDebugMode ? 9999 : 2)).map((event) => (
          <GridListTile key={event.id} component={Link} to={urlPrefix + "/event/" + event.alias} className={classes.tile}>
            {!event.hasAccess &&
              <GridListTileBar
                title={!event.canRegister ? "Registration coming soon" : "Click to register"}
                className={classes.bar}
                titlePosition="top"
              />
            }
            <img src={event.imageUrl} alt={event.name} className={classes.image} />
            <GridListTileBar
              title={event.name}
              subtitle={formatDateInterval(event.startDate, event.endDate)}
              // title={formatDateInterval(event.startDate, event.endDate)}
              classes={{ title: classes.title }}
              className={classes.bottomBar}
            />
          </GridListTile>
        ))}
      </GridList>
    </>
  );
}

export default EventsGrid;
